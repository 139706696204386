const FontFaceObserver = require("fontfaceobserver");
// import FontFaceObserver from 'fontfaceobserver'

const Fonts = () => {
  if (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development" ||
    window.location.origin !== "https://wunderfauks.com"
  ) {
    // dev code
    const link = document.createElement("link");
    link.href = "https://fonts.cdnfonts.com/css/open-sans";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    const font = new FontFaceObserver("open sans");
    font.load(null, 5000).then(() => {
      document.documentElement.classList.add("OpenSans");
    });

    const link1 = document.createElement("link");
    link1.href = "https://fonts.cdnfonts.com/css/norwester";
    link1.rel = "stylesheet";
    document.head.appendChild(link1);
    const font1 = new FontFaceObserver("norwester");
    font1.load(null, 5000).then(() => {
      document.documentElement.classList.add("norwester");
    });
  } else {
    // production code
    const link = document.createElement("link");
    link.href = "https://wunderfauks.com/fonts/OpenSans.css?ver=1";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    const font = new FontFaceObserver("OpenSans");
    font.load().then(() => {
      document.documentElement.classList.add("OpenSans");
    });
    const link1 = document.createElement("link");
    link1.href = "https://wunderfauks.com/fonts/Norwester.css?ver=1";
    link1.rel = "stylesheet";
    document.head.appendChild(link1);
    const font1 = new FontFaceObserver("norwester");
    font1.load().then(() => {
      document.documentElement.classList.add("norwester");
    });
  }
};

export default Fonts;
