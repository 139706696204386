import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import Radium, { StyleRoot } from 'radium';
import StudioContact from './StudioContact';
import $ from 'jquery'


export default class Footer extends Component {

   handleAbout = () => {
      $("#menu-button").click();
   }

   render() {
      const studios = this.props.studios;
      let pathname = window.location.pathname;
      return (
         <div className='footer-wrap container-fluid' id='footer-wrap'   >
            <Row  >
               <Col md={12} xs={12}>
                  <h1 className={'footer-discuss'}>Interested? Lets Discuss.</h1>
               </Col>
               <Col md={10} xs={12} className={'locations'}>
                  <Row className={'studios'}>
                     {studios && studios.map((studio, i) => {
                        return (
                           <Col key={i} className="footer-clock-div" md={5}>
                              <StudioContact location={'footer'} studio={studio} />
                           </Col>
                        );
                     })}
                  </Row>
               </Col>
               <Col md={2} xs={12} className='container right-menus'>

                  <div className='soc-med visible-xs'  >
                     <div>
                        <a href="https://www.facebook.com/Wunderfauks/" target="_blank" rel="noopener noreferrer"
                        >
                           <div className="facebook" />
                        </a>
                     </div>

                     <div>
                        <a href="https://www.instagram.com/wunderfauks/" target="_blank" rel="noopener noreferrer"
                        >
                           <div className="instagram" />
                        </a>
                     </div>
                     <div>
                        <a href="https://sg.linkedin.com/company/wunderfauks" target="_blank" rel="noopener noreferrer"
                        >
                           <div className="linkedin" />
                        </a>
                     </div>
                  </div>

                  <Row className='footer-menu'>

                     <Col md={12}  className="col-xs-5ths" style={{ display: window.innerWidth < 767 ? 'inline-block' : 'none' } } >
                        {
                           pathname == '/about' ?
                           <a href="#">About</a>
                           :
                           <Link to={'/about'} >About</Link>
                        }
                     </Col>

                     <Col md={12}  className="col-xs-5ths" style={{ display: window.innerWidth > 767 ? 'block' : 'none' } } >
                        {
                           <p onClick={this.handleAbout}>About</p>
                        }
                     </Col>

                     <Col md={12}  className="col-xs-5ths" >
                        {
                           pathname == '/work' ?
                           <a href="#" >Work</a>
                           :
                           <Link to={'/work'}>Work</Link>
                        }
                     </Col>

                     <Col md={12}  className="col-xs-5ths" >
                        {
                           pathname == '/services' ?
                           <a href="#" >Services</a>
                           :
                           <Link to={'/services'}>Services</Link>
                        }
                     </Col>
                     <Col md={12} className="col-xs-5ths" >
                        {
                           pathname == '/services' ?
                           <a href="/services#approach" >Approach</a>
                           :
                           <Link to={'/services#approach'}>Approach</Link>
                        }
                     </Col>
                     <Col md={12} className="col-xs-5ths" >
                        {
                           pathname == '/careers' ?
                           <a href="#" >Careers</a>
                           :
                           <Link to={'/careers'}>Careers</Link>
                        }
                     </Col>
                  </Row>
                  <div className='soc-med hidden-xs'  >
                     <div>
                        <a href="https://sg.linkedin.com/company/wunderfauks" target="_blank" rel="noopener noreferrer"
                        >
                           <div className="linkedin" />
                        </a>
                     </div>

                     <div>
                        <a href="https://www.instagram.com/wunderfauks/" target="_blank" rel="noopener noreferrer"
                        >
                           <div className="instagram" />
                        </a>
                     </div>
                     <div>
                        <a href="https://www.facebook.com/Wunderfauks/" target="_blank" rel="noopener noreferrer"
                        >
                           <div className="facebook" />
                        </a>
                     </div>
                  </div>
               </Col>
            </Row>
            <div className={'bottom'}>
               <div className={'copyright'}>© {new Date().getFullYear()}</div>
               <div className={'slogan'}>An Integrated Creative Agency.</div>
            </div>
         </div>

      );
   }
}
