import React, { Component } from 'react';
import MtSvgLines from 'react-mt-svg-lines';

class Arrow1 extends Component {
  constructor() {
    super();
    this.state = {
      hidden: 'hidden',
    }
  }
  componentWillMount() {
    let self = this;
    setTimeout(() => {
      self.show();
    }, self.props.wait)
  }
  show() {
    this.setState({
      hidden: 'show',
    })
  }
  render() {
    return (
      <div className={ this.state.hidden }>
        <MtSvgLines animate={ true } duration={400}>
          <svg
            width="114.432px" height="233.833px">
            <g>
              <path fill="none" stroke="#808080" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" d="
                M109.434,231 C50.32,231.603,10,170.85,8.5,120.902 c0,0,0,-90.432,100.432,-111.432 "/>
            </g>
          </svg>
        </MtSvgLines>
      </div>
    )
  }
}

export default Arrow1;
