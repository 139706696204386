import React, { Component } from 'react';
import MtSvgLines from 'react-mt-svg-lines';

class Arrow2 extends Component {
  constructor() {
    super();
    this.state = {
      hidden: 'hidden',
    }
  }
  componentWillMount() {
    let self = this;
    setTimeout(() => {
      self.show();
    }, self.props.wait)
  }
  show() {
    this.setState({
      hidden: 'show',
    })
  }
  render() {
    return (
      <div className={ this.state.hidden }>
      <MtSvgLines animate={true} duration={400}>
        <svg width="167.918px" height="21.691px">
          <g>
            <path fill="none" stroke="#808080" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"
              d="M157.418,10.846l-147.918-0"/>

          </g>
        </svg>
      </MtSvgLines>
      </div>
    )
  }
}

export default Arrow2;
