import React, { Component } from 'react';
import axios from 'axios';
import { Container as Grid, Row, Col } from 'react-bootstrap';
import Radium, { StyleRoot } from 'radium';
import { fadeInUp, fadeOut, fadeIn } from 'react-animations';
import { Fade } from 'react-reveal';
import ResourceUrl from '../resource';
import Container from './Container';
import OverlappingCirc from './OverlappingCirc';
import Arrow1 from './arrows/arrow-1';
import Arrow1a from './arrows/arrow-1a';
import Arrow2 from './arrows/arrow-2';
import Arrow2a from './arrows/arrow-2a';
import Arrow3 from './arrows/arrow-3';
import Arrow3a from './arrows/arrow-3a'
import Arrow4 from './arrows/arrow-4';
import Arrow4a from './arrows/arrow-4a';
import Arrow5 from './arrows/arrow-5';
import Arrow5a from './arrows/arrow-5a';
import Footer from './Footer';
import MobileApproaches from '../assets/mobile-approaches.png'
import rightArrowThin from '../assets/right-arrow-thin.png'
import leftArrowThin from '../assets/left-arrow-thin.png'
import {Helmet} from "react-helmet";
import scrollToComponent from 'react-scroll-to-component';

import accor from '../assets/logos/accor_logo.png';
import aerotel from '../assets/logos/aerotel_logo.png'
import clearChannel from '../assets/logos/clearchannel_logo.png'
import dhl from '../assets/logos/dhl_logo.png'
import dior from '../assets/logos/dior_logo.png'
import energyMarket from '../assets/logos/energymarketauthority_logo.png'
import fomo from '../assets/logos/fomo_logo.png'
import heinz from '../assets/logos/heinz_logo.png'
import hospital from '../assets/logos/khooteckpuathospital_logo.png'
import lvmh from '../assets/logos/lvmh_logo.png'
import welfareServices from '../assets/logos/methodistwelfareservices_logo.png'
import nus from '../assets/logos/nus_logo.png'
import nyc from '../assets/logos/nyc_logo.png'
import lounge from '../assets/logos/plazapremiumlounge_logo.png'
import polis from '../assets/logos/polis_logo.png'
import selangor from '../assets/logos/royalselangor_logo.png'
import stElectronics from '../assets/logos/stelectronics_logo.png'
import stLogostic from '../assets/logos/stlogistics_logo.png'
import swire from '../assets/logos/swire_logo.png'
import wEnergy from '../assets/logos/wenergydrink_logo.png'
import pistachios from '../assets/logos/wonderfulpistachios_logo.png'
import travel from '../assets/logos/ls_travel_retail_logo.png'
import ntu from '../assets/logos/ntu_logo.png'
import medilot from '../assets/logos/medilot_logo.png'
import astar from '../assets/logos/astar_logo.png'



import Loading from 'react-progress-bar-plus'



import downArrow from '../assets/down.png'

const styles = {
   approachesList: {
      marginTop: '22%',
      container: {
         letterSpacing: '.025em',
         marginBottom: '25px',
         paddingLeft: '1%',
         paddingRight: '0',

         '@media (maxWidth: 767px)': {
            paddingLeft: '30px',
         },
         category: {
            color: '#808080',
            fontSize: '7.5pt',
            marginBottom: '5px',
         },
         contentTitle: {
            color: '#1A1A1A',
            marginTop: '0',
            textTransform: 'uppercase',
            fontSize: '14pt',
            fontWeight: '800',
            height: '25px',
         },
         contentText: {
            color: '#4D4D4D',
            fontSize: '16px',
            lineHeight: '2',
            maxWidth: '85%',
            marginTop: '30px'
         },
      },
   },
   fadeInUp: {
      animation: 'x 0.5s ease',
      animationName: Radium.keyframes(fadeInUp, 'slideup'),
   },
   fadeIn: {
      animation: 'x 0.5s',
      animationName: Radium.keyframes(fadeIn, 'fadeIn'),
   },
   fadeOut: {
      animation: 'x 4s ease',
      animationName: Radium.keyframes(fadeOut, 'fadeOut'),
   },
   button: {
      background: 'transparent',
      border: 'none',
      position: 'absolute',
      left: '0',
      right: '0',
      width: '100%',
      transform: 'translateY(-100%)',
      ':focus': {
         outline: 'none',
      },
   },
};

export default class Services extends Component {

   constructor(props) {
      super(props);
      this.state = {
         approaches: [],
         displayArrows: false,
         rootTop: false,
         showBanner: true,
         yearCount: 0,
         selected: 'Brainwork', // Init,  Brainwork, Implementation
         showLoading: true,
         loadingStatus: 0,
      };
      this.triggerArrows = this.triggerArrows.bind(this);
      this.renderApproaches = this.renderApproaches.bind(this);

   }
   isIE = /*@cc_on!@*/false || !!document.documentMode;

   componentDidMount() {
      let _this = this
      let hash = window.location.hash.substr(1)
      setTimeout(function () {
         window.scrollTo(0, 0);
      }, 400);

      axios
      .get(ResourceUrl.url + 'approach')
      .then(res => {
         setTimeout(function(){
            _this.setState({
               approaches: res.data,
               loadingStatus: 100
            });
            setTimeout(function(){
               _this.setState({
                  showLoading: false
               })
               if(hash == 'approach'){
                  setTimeout(function () {
                     scrollToComponent(_this.approachScrollDiv, {
                        align: 'top',
                        duration: 3000,
                        ease: 'inOutQuint'
                     });
                  }, 500);
               }
            }, 200);
         }, 700);


      })
      window.addEventListener('scroll', this.triggerArrows);
      const cYear = new Date().getFullYear();
      this.setState({
         yearCount: cYear - 2010,
         loadingStatus: 40
      })
   }
   componentWillUnmount() {
      this.setState({ displayArrows: false })
      window.removeEventListener('scroll', this.triggerArrows);

   }


   triggerArrows(event) {
      let approachScroll = document.getElementById('approachScroll');
      if (approachScroll) {
         let distanceToTop = approachScroll.getBoundingClientRect().top;

         if (distanceToTop <= 600) {
            this.setState({
               displayArrows: true,
            });
         } else{
            this.setState({
               displayArrows: false,
            });
         }

      }
      let scrollview = document.getElementById('overlap').getBoundingClientRect().top;
      if (scrollview < 0) {
         this.setState({ showBanner: false });
      } else {
         this.setState({ showBanner: true });
      }

   }

   renderApproaches() {
      let approachesList = [];
      let {approaches, selected} = this.state;
      let windowWidth = window.innerWidth;
      if (approaches && approaches.length > 0) {
         for (let i = 0; i < approaches.length; i++) {
            if(windowWidth > 787){
               if(approaches[i].acf.category == selected ){
                  approachesList.push(
                     <Col md={4} xs={12} key={i} className='approaches-div' style={styles.approachesList.container}>
                        <Fade left={selected === 'Brainwork' && true }  right={selected === 'Implementation' && true }
                           key={i}  >
                           <p style={styles.approachesList.container.category}>
                              <span>/ </span>
                              {approaches[i].acf.category}
                           </p>
                           <h3 style={styles.approachesList.container.contentTitle}>
                              {approaches[i].title.rendered.replace(/#?[a-z0-9]+;/i, '')}
                           </h3>
                           <p style={styles.approachesList.container.contentText}>
                              {approaches[i].content.rendered.replace(/<\/?[^>]+(>|$)/g, '')}
                           </p>

                        </Fade>
                     </Col>
                  )
               }
            }else{
               approachesList.push(
                  <Col md={4} xs={12} key={i} className='approaches-div' style={styles.approachesList.container}>
                     <Fade bottom key={i} >
                        <p style={styles.approachesList.container.category}>
                           <span>/ </span>
                           {approaches[i].acf.category}
                        </p>
                        <p style={[styles.approachesList.container.contentTitle, {height: 'unset'}]}>
                           {approaches[i].title.rendered.replace(/#?[a-z0-9]+;/i, '')}
                        </p>
                        <p style={[styles.approachesList.container.contentText, {maxWidth: 'unset'}]}>
                           {approaches[i].content.rendered.replace(/<\/?[^>]+(>|$)/g, '')}
                        </p>

                     </Fade>
                  </Col>
               )
            }

         }
         if(windowWidth > 787){
            approachesList.push(
               <Col md={4} xs={12} key={'arrow'}>
                  <Fade left >
                     <div className="service-buttons"
                        onClick={()=>{this.setState({selected: 'Brainwork'})}}
                        style={{background: selected === 'Brainwork' ? 'gray' : 'black' , paddingLeft: '5px'}}>
                        {/* &#x2329; */}
                        <img src={leftArrowThin} />
                     </div>
                     <div className="service-buttons"
                        onClick={()=>{this.setState({selected: 'Implementation'})}}
                        style={{background: selected === 'Implementation' ? 'gray'  : 'black', paddingLeft: '10px'}}>
                        {/* &#x232A; */}
                        <img src={rightArrowThin} />
                     </div>
                  </Fade>
               </Col>
            )
         }
      }
      return approachesList;
   }
   render() {
      const { rootTop, showBanner, yearCount, loadingStatus, showLoading } = this.state;
      let windowWidth = window.innerWidth

      return (
         <StyleRoot>
            <Helmet>
               <title>Wunderfauks | An Integrated Creative Agency | Services, Approaches and Clients</title>
               <meta name="description" content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses." />
            </Helmet>

            <div>
               {showLoading &&
                  <div className={"loader"}   >
                     <Loading
                        percent={loadingStatus}
                        spinner={false}
                        autoIncrement={true}
                        intervalTime={50}
                     />
                  </div>
               }

               <div id="banner-static" style={{ zIndex: showBanner ? '-1' : '-3', display: showBanner ? 'block' : 'none' }} >
                  {!showLoading &&
                     <Container title={'We love'} title1='what we do' subtitle={yearCount + ' years & counting...'} buttonText={'Services'} />
                  }
                  <button
                     className={'down-button'}
                     onClick={() => scrollToComponent(this.approaches, { offset: 0, align: 'top', duration: 1000 })}  >
                     <img src={downArrow} alt={'down arrow'} />
                  </button>

               </div>

               <div id="content-scrollview" className="children" >
                  <div
                     ref={section => {
                        this.approaches = section;
                     }}
                     style={{ backgroundColor : '#1C1B1D' }}>
                     <div className={'services-about-cont'}>
                        <Grid id={'services-about'}>
                           <Row>
                              <Col md={1}></Col>
                              <Col md={10} className="services-about-col">
                                 <p>
                                    We are an integrated creative agency focusing on new and innovative experiences. From the likes of creative expression to campaign execution, communication and creative implementation, Wunderfauks provides tailored bulls-eye solutions that focus on results over activities.
                                    <br/><br/>
                                    With digital as our strong suit, we have a dynamic team comprises of multi-disciplinary individuals with their own think tanks of interesting ideas and concepts to suit any need, logic and aspiration.
                                 </p>
                              </Col>
                              <Col md={1}></Col>
                           </Row>
                        </Grid>
                     </div>
                  </div>
                  <div
                     ref={section => {
                        this.approaches = section;
                     }}
                     style={{ backgroundColor: '#FFFFFF' }}
                     >
                        <div className={'approaches'}>
                           <Grid id={'overlap'}>
                              <OverlappingCirc />
                              <Row>
                                 <Col md={5} id="brainwork-col">
                                    <div className={'brain'} />
                                    <h2>Brainwork</h2>
                                    <p>Strategize & Conceptualize</p>
                                 </Col>
                                 <Col md={1}>
                                    <p>+</p>
                                 </Col>
                                 <Col md={5} style={{marginLeft: '-15px'}}>
                                    <div className={'implementation'} />
                                    <h2>Implementation</h2>
                                    <p>ATL & BTL stuff</p>
                                 </Col>
                              </Row>

                              <Row className="service-ib">
                                 <Col md={12} style={styles.approachesList}>
                                    <Row>{this.renderApproaches()}</Row>
                                 </Col>
                              </Row>

                           </Grid>
                        </div>
                     </div>
                     <div ref={i=>{this.approachScrollDiv = i}} id={'approachScroll'} className={'our-approach'}>
                        <Grid>
                           <Row>
                              <Col md={4} xs={12}>
                                 <h1 style={{fontWeight: 'normal'}}>Our Approach</h1>
                                 <p>We're not a <b style={{fontWeight: '800'}}>'YES MAN'</b> team.</p>
                                 <p style={{ margin: '30px 0 0' }}>
                                    We love to work <b style={{fontWeight: '800'}}>with</b> our clients and share/bounce-off our ideas & opinions with each other.
                                 </p>
                              </Col>

                              {windowWidth < 787 ?
                                 <img width='100%' src={MobileApproaches} />
                                 : this.isIE ?
                                 <Col md={8} >
                                    <img width="100%" src={MobileApproaches} />
                                 </Col>
                                 :
                                 <Col md={8} xs={12} id={'arrowParent'}>
                                    <Row id={'arrows-container'}>
                                       <Col className={'arrowWrap col-md-offset-2'} md={{span: 2}} >
                                          {this.state.displayArrows &&
                                             <div>
                                                <div id="arrow1" >
                                                   <Arrow1 wait={3200} />
                                                </div>
                                                <div id="arrow1a" >
                                                   <Arrow1a wait={3600} />
                                                </div>
                                             </div>
                                          }

                                       </Col>
                                       <Col md={2}>
                                          {this.state.displayArrows && (
                                             <div>
                                                <Fade big delay={0}>
                                                   <span id="text1">UNDERSTAND</span>
                                                </Fade>
                                                <Fade big delay={2900}>
                                                   <span id="text5">ANALYSE</span>
                                                </Fade>
                                             </div>
                                          )}
                                       </Col>
                                       <Col className={'arrowWrap'} md={2}>
                                          {this.state.displayArrows && (
                                             <div>
                                                <div id="arrow2" >
                                                   <Arrow2 wait={2400} />
                                                </div>
                                                <div id="arrow2a" >
                                                   <Arrow2a wait={2600} />
                                                </div>
                                                <div id="arrow3">
                                                   <Arrow3 wait={0} />
                                                </div>
                                                <div id="arrow3a">
                                                   <Arrow3a wait={400} />
                                                </div>
                                             </div>
                                          )}
                                       </Col>
                                       <Col md={2}>
                                          {this.state.displayArrows && (
                                             <div>
                                                <Fade big delay={500}>
                                                   <span id="text2" >DELIBERATE</span>
                                                </Fade>
                                                <Fade big delay={2000}>
                                                   <span id="text4" >EXECUTE</span>
                                                </Fade>
                                             </div>
                                          )}
                                       </Col>
                                       <Col className={'arrowWrap'} md={2}>
                                          {this.state.displayArrows && (
                                             <div>
                                                <div id="arrow4" >
                                                   <Arrow4 wait={800} />
                                                </div>
                                                <div id="arrow4a" >
                                                   <Arrow4a wait={1200} />
                                                </div>

                                                <Fade big delay={1300} >
                                                   <div id="text3">
                                                      CREATE
                                                   </div>
                                                </Fade>

                                                <div>
                                                   <div id="arrow5" >
                                                      <Arrow5 wait={1600} />
                                                   </div>
                                                   <div id="arrow5a">
                                                      <Arrow5a wait={2000} />
                                                   </div>
                                                </div>
                                             </div>
                                          )}
                                       </Col>
                                    </Row>
                                 </Col>
                              }
                           </Row>
                        </Grid>
                     </div>

                     <Grid fluid={true} className="clients-wrap">
                        <h1 style={{fontWeight: 'normal'}}>Some of our esteemed clients</h1>
                        <Row className={'clients'}>
                           <Col md={{ span: 2 }} className="col-md-offset-1"xs={4}>
                              <img src={accor} alt={'wunderfauks clients accor logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={aerotel} alt={'wunderfauks clients aerotel logo'} />
                           </Col>
                           <Col md={2} xs={4}  >
                              <img src={astar} alt={'wunderfauks clients a star logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={clearChannel} alt={'wunderfauks clients clearChannel logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={dhl} alt={'wunderfauks clients dhl logo'} />
                           </Col>
                           <Col md={{ span: 2 }} className="col-md-offset-1"xs={4} >
                              <img src={dior} alt={'wunderfauks clients dior logo'} />
                           </Col>
                           <Col md={2}  xs={4} >
                              <img src={energyMarket} alt={'wunderfauks clients energyMarket logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={fomo} alt={'wunderfauks clients fomo logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={heinz} alt={'wunderfauks clients heinz logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={hospital} alt={'wunderfauks clients hospital logo'} />
                           </Col>
                           <Col md={{ span: 2 }} className="col-md-offset-1"xs={4} >
                              <img src={lvmh} alt={'wunderfauks clients lvmh logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={travel} alt={'wunderfauks clients ls travel retail logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={medilot} alt={'wunderfauks clients medilot logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={welfareServices} alt={'wunderfauks clients welfareServices logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={ntu} alt={'wunderfauks clients ntu logo'} />
                           </Col>
                           <Col md={{ span: 2 }} className="col-md-offset-1"xs={4} >
                              <img src={nus} alt={'wunderfauks clients nus logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={nyc} alt={'wunderfauks clients nyc logo'} />
                           </Col>

                           <Col md={2} xs={4} >
                              <img src={lounge} alt={'wunderfauks clients plaza premium lounge logo'} />
                           </Col>
                           <Col md={2} xs={4}  >
                              <img src={polis} alt={'wunderfauks clients polis logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={stElectronics} alt={'wunderfauks clients st Electronics logo'} />
                           </Col>
                           <Col md={{ span: 2 }} className="col-md-offset-1"xs={4}>
                              <img src={stLogostic} alt={'wunderfauks clients st Logistic logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={swire} alt={'wunderfauks clients swire logo'} />
                           </Col>
                           <Col md={2} xs={4} >
                              <img src={wEnergy} alt={'wunderfauks clients W Energy logo'} />
                           </Col>
                           <Col md={2} xs={4}  >
                              <img src={pistachios} alt={'wunderfauks clients wonderful pistachios logo'} />
                           </Col>




                        </Row>
                     </Grid>
                     {/* <Footer studios={this.props.studios} /> */}
                  </div>
               </div>
            </StyleRoot>
         );
      }
   }
