import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import downArrow from "../assets/down.png";
import Container from "./Container";
import scrollToComponent from "react-scroll-to-component";
import Loading from "react-progress-bar-plus";
import { Container as Grid, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";

const SmeInitiative = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState();
  const smeDetails = useRef(null);
  const formattedBody =
    "Hi,\nI would like to apply for Wunderfauks's SME Initiative - Website Design and Development.\n(Do attach your ACRA & 1 Scanned utilities to justify business location)";
  const mailTo =
    "mailto:sme@wunderfauks.com?subject=Request for Application of SME Initiative - Website Design and Development&body=" +
    encodeURIComponent(formattedBody);

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 400);
    setTimeout(function () {
      setLoadingStatus(100);
      setTimeout(function () {
        setShowLoading(false);
      }, 200);
    }, 700);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Wunderfauks | An Integrated Creative Agency | SME Initiative
        </title>
        <meta
          name="description"
          content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses."
        />
      </Helmet>
      <div>
        {showLoading && (
          <div className={"loader"}>
            <Loading
              percent={loadingStatus}
              spinner={false}
              autoIncrement={true}
              intervalTime={50}
            />
          </div>
        )}
        <div
          id="banner-static"
          style={{
            zIndex: showBanner ? "-1" : "-3",
            display: showBanner ? "block" : "none",
          }}
        >
          <Container
            title="SME INITIATIVE"
            subtitle={"Website Design and Development - SME Initiative Grant"}
            buttonText={"SME Requirements"}
          />

          <button
            className={"down-button"}
            onClick={() =>
              scrollToComponent(smeDetails.current, {
                offset: 0,
                align: "top",
                duration: 1000,
              })
            }
          >
            <img src={downArrow} alt={"down arrow"} />
          </button>
        </div>
        <div id="content-scrollview" className="children">
          <div style={{ backgroundColor: "#1C1B1D" }} ref={smeDetails}>
            <div className={"services-about-cont"}>
              <Grid id={"services-about"}>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10} className="services-about-col">
                    <p>
                      As a digital-first integrated creative agency committed to
                      supporting small and medium-sized enterprises (SMEs), we
                      are thrilled to announce our initiative to give back and
                      provide assistance through the creation of websites. We
                      firmly believe that in today's digital landscape, every
                      business requires a minimal online presence, and a website
                      serves as a vital tool for success. With our extensive
                      13-year experience in the industry, we are grateful for
                      the opportunities we have had, and now it's time for us to
                      express our gratitude by helping SMEs establish their
                      digital presence and thrive in the online world.
                      <br />
                      <br />
                      In our commitment to support SMEs, we are excited to
                      introduce our monthly program that aims to assist 10 new
                      SMEs each month. Through this program, SMEs will have
                      access to a range of professionally designed templates,
                      allowing them to choose the one that best aligns with
                      their brand identity. From there, they can customize and
                      tailor their website to meet their specific needs. To make
                      this opportunity even more accessible, we are offering
                      these services at a significantly discounted rate,
                      ensuring that cost is not a barrier for SMEs seeking to
                      establish their online presence. With our streamlined
                      process, SMEs can expect a fast turnaround time, with a
                      lead-time of just two weeks.
                    </p>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              </Grid>
            </div>
          </div>
          <div className="sme-initiative-inner">
            <Grid>
              <Row>
                <Fade bottom>
                  <Col md={8} xs={12}>
                    <span className={"categories"}>
                      You will just need to check your applicability:
                    </span>
                    <h1 className={"title"}>
                      Website Design & Development Grant(Singapore):
                    </h1>
                    <p className="details">
                      <p>- Not more than 2 years in business</p>
                      <p>- Have at least 51% Singapore shareholding</p>
                      <p>- Business to be or is operating in Singapore</p>
                    </p>
                    <h1 className={"documentation-title title"}>
                      Documentation:
                    </h1>
                    <p className="details">
                      <p>- ACRA (within 2 months)</p>
                      <p>- Scanned utilities to justify business location</p>
                      <p>
                        - Zoom call for requirements and identity confirmation
                      </p>
                    </p>

                    <div className="email">
                      <p>
                        Do email respective details to{" "}
                        <a id="sme-apply2" href={mailTo}>
                          sme@wunderfauks.com
                        </a>
                        .
                      </p>
                    </div>

                    <div className="finaltext">
                      <p>
                        The 100% Initiative by Wunderfauks proudly stands
                        independent of any external organizational support or
                        grants in Singapore, including the Productivity
                        Solutions Grant (PSG) and other similar programs and
                        will run for 1 year from July 2023 till July 2024.
                      </p>
                    </div>
                  </Col>
                  <Col md={3} xs={12}>
                    <a id="sme-apply" href={mailTo}>
                      APPLY NOW
                    </a>
                  </Col>
                </Fade>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmeInitiative;
