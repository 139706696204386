import React, { Component } from 'react';
import MtSvgLines from 'react-mt-svg-lines';

class Arrow3 extends Component {
  constructor() {
    super();
    this.state = {
      hidden: 'hidden',
    }
  }
  componentWillMount() {
    let self = this;
    setTimeout(() => {
      self.show();
    }, self.props.wait)
  }
  show() {
    this.setState({
      hidden: 'show',
    })
  }
  render() {
    return (
      <div className={ this.state.hidden }>
        <MtSvgLines animate={true} duration={400}>
          <svg width="83.563px" height="21.692px">
            <g>
              <path fill="none" stroke="#808080" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"
                d="M1.5,10.846l73.563-0"/>
              </g>
            </svg>
          </MtSvgLines>
        </div>
      )
    }
  }


  export default Arrow3;
