import React, { Component } from "react";
import * as THREE from "three";
import Disc from "../images/disc.png";

export default class PointsAnimation extends Component {
  constructor(props) {
    super(props);

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
    this.geometry = new THREE.BufferGeometry();
    this.mount = React.createRef(null);
  }

  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;
  camera;
  mousedown = false;
  mouseUp = false;
  firstZoom = true;

  componentDidMount() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    THREE.Cache.enabled = true;

    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.01, 3000);
    this.camera.position.z = 1000;
    const scene = new THREE.Scene();

    scene.background = new THREE.Color(0x1c1b1d);
    scene.fog = new THREE.FogExp2(0xffffff, 0.001);
    this.geometry = new THREE.BufferGeometry();

    let size = 0;
    if (width < 767) {
      size = height * 0.025;
    } else {
      size = height * 0.01;
    }
    let numOfPoints = 40;
    if (width < 767) {
      numOfPoints = 70;
    }
    const vertices = [];

    for (let i = 0; i < numOfPoints; i++) {
      // const vertex = new THREE.Vector3();
      // geometry.vertices.push(vertex);
      const x = Math.random() * 2000 - 1000;
      const y = Math.random() * 2000 - 1000;
      const z = Math.random() * 2000 - 1000;
      vertices.push(x, y, z);
    }
    this.geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );
    // const materials = new THREE.PointsMaterial({
    //   size: size,
    //   map: sprite,
    //   transparent: true,
    //   alphaTest: 0.5,
    //   color: "#fff",
    // });
    // materials.color.setRGB(0.9, 0.9, 0.9);
    // const particles = new THREE.Points(this.geometry, materials);
    // // particles.rotation.x = Math.random() * 6;
    // // particles.rotation.y = Math.random() * 6;
    // // particles.rotation.z = Math.random() * 6;
    // scene.add(particles);
    for (let i = 0; i < 4; i++) {
      const materials = new THREE.PointsMaterial({
        size: size,
        map: new THREE.TextureLoader().load(Disc),
        transparent: true,
        alphaTest: 0.5,
        color: "#fff",
      });
      materials.color.setRGB(0.9, 0.9, 0.9);

      const particles = new THREE.Points(this.geometry, materials);
      particles.rotation.x = Math.random() * 6;
      particles.rotation.y = Math.random() * 6;
      particles.rotation.z = Math.random() * 6;
      scene.add(particles);
    }

    const renderer = new THREE.WebGLRenderer();

    renderer.setSize(width, height);

    this.scene = scene;
    this.renderer = renderer;
    if (this.mount) {
      this.mount.current.appendChild(this.renderer.domElement);
      this.start();
    }
    window.addEventListener("resize", this.onWindowResize.bind(this), false);
  }

  componentWillUnmount() {
    this.stop();
    this.mount.current.removeChild(this.renderer.domElement);
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  animate() {
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  }

  renderScene() {
    const time = Date.now() * 0.00002;
    let { camera, scene, renderer, firstZoom } = this;
    let { pMouseX, pMouseY, pMouseDown, pMouseUp, zoom } = this.props;

    camera.position.x += (pMouseX - camera.position.x) * 0.15;
    camera.position.y += (-pMouseY - camera.position.y) * 0.15;
    camera.lookAt(scene.position);

    if (!firstZoom) {
      if (pMouseDown) {
        if (camera.fov > 50) {
          camera.fov *= 0.9;
          camera.updateProjectionMatrix();
        }
      }
      if (pMouseUp) {
        if (camera.fov < 75) {
          camera.fov *= 1.05;
          camera.updateProjectionMatrix();
        }
      }
    }
    if (firstZoom) {
      if (zoom) {
        if (camera.fov <= 75) {
          camera.fov *= 1.11;
          camera.updateProjectionMatrix();
        } else {
          this.firstZoom = false;
        }
      } else {
        camera.fov = 0.01;
        camera.updateProjectionMatrix();
      }
    }
    for (let i = 0; i < scene.children.length; i++) {
      var object = scene.children[i];
      if (object instanceof THREE.Points) {
        object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));
      }
    }
    this.geometry.needsUpdate = true;
    renderer.render(scene, camera);
  }

  onWindowResize() {
    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    return (
      <div>
        <div
          id="stars"
          ref={this.mount}
          // ref={(mount) => {
          //   this.mount = mount;
          // }}
        />
      </div>
    );
  }
}
