import React, { Component } from "react";
import { fadeIn, fadeInRight, fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import { Row, Container, Col } from "react-bootstrap";
import StudioContact from "./StudioContact";
import { Link } from "react-router-dom";

const styles = {
  fadeInRight: {
    animation: "x 1s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInOnly: {
    animation: "x 1s ease",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeInAbout1: {
    animation: "x 0.4s",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInAbout2: {
    animation: "x 0.8s",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInSocial: {
    animation: "x 0.8s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInSocial1: {
    animation: "x 0.6s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInSocial2: {
    animation: "x 1.5s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInSocial3: {
    animation: "x 2.4s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
};

export default class ContactDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactOpen: true,
    };
  }

  componentDidMount() {
    if (window.innerWidth < 767) {
      this.props.history.push("/about#contact");
    }
    let hash = window.location.hash.substr(1);
    if (hash == "about") {
      this.setState({
        contactOpen: false,
      });
    }
  }

  handleContact = (event) => {
    event.preventDefault();
    this.setState({
      contactOpen: true,
    });
  };
  handleAbout = (event) => {
    event.preventDefault();
    this.setState({
      contactOpen: false,
    });
  };

  renderStudios() {
    const studios = this.props.studios;
    return (
      studios &&
      studios.map((studio, i) => {
        return (
          <Col md={6} key={i}>
            <StudioContact location={"menu"} key={studio.id} studio={studio} />
          </Col>
        );
      })
    );
  }

  renderInnerMenuContent() {
    const { contactOpen } = this.state;
    if (contactOpen) {
      return (
        <StyleRoot>
          <div className={"menu-contact"} style={{ marginTop: "20px" }}>
            <h5 style={[styles.fadeInAbout1, { color: "#141414" }]}>Contact</h5>
            <h1 className={"menu-discuss"} style={styles.fadeInAbout1}>
              Interested? Lets Discuss.
            </h1>
            <Container>
              <Row>
                <StyleRoot>
                  <Col md={10} xs={12} className={"locations"}>
                    <Row className={"studios"}>{this.renderStudios()}</Row>
                  </Col>
                </StyleRoot>
              </Row>
            </Container>
          </div>
        </StyleRoot>
      );
    } else {
      return (
        <div className={"about"}>
          <h5 style={[styles.fadeInAbout1, { color: "#141414" }]}>About</h5>
          <p style={styles.fadeInAbout1}>
            Wunderfauks is an integrated creative agency focusing on new and
            innovative experiences. From the likes of creative expression to a
            campaign execution, communication and creative implementation,
            Wunderfauks provides tailored bulls-eye solutions that focus on
            results over activities.
          </p>
          <p style={styles.fadeInAbout2}>
            With digital as our strong suit, we have a dynamic team comprising
            of multi-disciplinary individuals with their own think tanks of
            interesting ideas and concepts to suit any need, logic and
            aspiration.
          </p>
        </div>
      );
    }
  }

  renderLinks() {
    const { contactOpen } = this.state;
    return (
      <StyleRoot>
        <div style={styles.fadeIns}>
          <div style={styles.fadeInUp1}>
            <Link to="/work" onClick={this.closeMenu}>
              Work
            </Link>
          </div>
          <div style={styles.fadeInUp12}>
            <Link to="/services" onClick={this.closeMenu}>
              Services
            </Link>
          </div>
          <div style={styles.fadeInUp2}>
            <Link to="/services#approach" onClick={this.closeMenu}>
              Approach
            </Link>
          </div>
          <div style={styles.fadeInUp3}>
            <Link to="/careers" onClick={this.closeMenu}>
              Careers
            </Link>
          </div>
          <div style={styles.fadeInUp4}>
            {!contactOpen ? (
              <a href="#" className={"contact"} onClick={this.handleContact}>
                Contact
              </a>
            ) : (
              <a href="#" className={"contact"} onClick={this.handleAbout}>
                About
              </a>
            )}
          </div>
        </div>
      </StyleRoot>
    );
  }

  renderSocial() {
    return (
      <StyleRoot>
        <div>
          <Row className={"menu-soc-med"}>
            <Col md={{ span: 2 }} xs={4} className="col-md-offset-6">
              <div style={styles.fadeInSocial1}>
                <a
                  href="https://www.facebook.com/Wunderfauks/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="facebook" />
                </a>
              </div>
            </Col>

            <Col md={2} xs={4}>
              <div style={styles.fadeInSocial2}>
                <a
                  href="https://www.instagram.com/wunderfauks/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="instagram" />
                </a>
              </div>
            </Col>

            <Col md={2} xs={4}>
              <div style={styles.fadeInSocial3}>
                <a
                  href="https://sg.linkedin.com/company/wunderfauks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="linkedin" />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </StyleRoot>
    );
  }

  render() {
    return (
      <StyleRoot>
        <Helmet>
          <title>Wunderfauks | An Integrated Creative Agency | Contact</title>
          <meta
            name="description"
            content="/SINGAPORE +65 6931 4638 contact@wunderfauks.com 68 Kallang Pudding Rd #06-04, Singapore 349327 "
          />
          <meta
            name="keywords"
            content="integrated creative agency,digital marketing solutions,marketing communications,advertising agency,social media and communications,brand strategy,mobile development"
          />
          <meta property="og:title" content="" />
          <meta property="og:image" content="" />
          <meta property="og:description" content="" />
          <meta itemprop="name" content="" />
          <meta itemprop="description" content="" />
          <meta itemprop="image" content="" />
        </Helmet>

        <div
          id="cover-menu-button"
          style={{
            background: "#141414",
            zIndex: "10000000",
            position: "fixed",
            width: "300px",
            height: "100px",
            right: "0",
          }}
        ></div>

        <div
          id="menu-items"
          style={{ display: "block", background: "#141414" }}
        >
          <Container fluid={true} className="menu-contact-div">
            {this.renderInnerMenuContent()}
          </Container>
          <div className="TopBar">{this.renderLinks()}</div>
          {this.renderSocial()}
          <div id="menu-footer">
            <p style={{ float: "left" }}>&copy; {new Date().getFullYear()}</p>
            <p style={{ float: "right" }}>An Integrated Creative Agency.</p>
          </div>
        </div>
      </StyleRoot>
    );
  }
}
