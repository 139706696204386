import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import CareerDefault from './CareerDefault';
import CareerInner from './CareerInner';

export default class Careers extends Component {

  render() {
    return (
      <div>
            <Route exact path={this.props.match.path} render={ (props) => <CareerDefault {...props} studios={this.props.studios} /> } />
            <Route path={`${this.props.match.path}/:id`} render={ (props) => <CareerInner {...props} studios={this.props.studios} /> } />
        </div>
    );
  }
}
