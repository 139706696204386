import React, { Component } from 'react';
import MtSvgLines from 'react-mt-svg-lines';

class Arrow3 extends Component {
  constructor() {
    super();
    this.state = {
      hidden: 'hidden',
    }
  }
  componentWillMount() {
    let self = this;
    setTimeout(() => {
      self.show();
    }, self.props.wait)
  }
  show() {
    this.setState({
      hidden: 'show',
    })
  }
  render() {
    return (
      <div className={ this.state.hidden }>
        <MtSvgLines animate={true} duration={400}>
          <svg width="117.834px" height="87.828px">
            <g>
              <path fill="none" stroke="#808080" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"
                 d={`M1.5,1.5C53.82,1.5,97.718,37.559,109,86`}/>
            </g>
          </svg>
        </MtSvgLines>
      </div>
    )
  }
}

export default Arrow3;
